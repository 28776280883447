<template>
  <div>
    <div class="grid grid-cols-3 gap-4">
      <div class="lg:col-span-2 col-span-3 clearfix">
        <div v-if="stockRequest.status === 'on_hold'" class="lg:float-left flex justify-end lg:mt-6">
          <vs-button v-if="$store.state.AppActiveUser.userRole !== 'fc-storing-agent' && stockRequest.status === 'on_hold'" @click="openStockProductModal('Add')" color="primary" class="py-2 px-3">{{ $t('Add more products') }}</vs-button>
        </div>
        <shipblu-table
        :sst="true"
          multiple
          v-model="selected"
          pagination
          :max-items="maximumItems"
          @search="handleSearch"
          @keyup.enter.native="handleSearch"
          search
          :data="UROProducts"
          :tableLoader="tableLoader"
        >
          <template slot="thead">
            <shipblu-th>{{$t('Product Image')}}</shipblu-th>
            <shipblu-th>{{$t('SKU')}}</shipblu-th>
            <shipblu-th>{{$t('Product Description')}}</shipblu-th>
            <shipblu-th>{{$t('Incoming Quantity')}}</shipblu-th>
            <shipblu-th>{{$t('Actions')}}</shipblu-th>
          </template>

          <template slot-scope="{ data }">
            <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <shipblu-td :data="data[indextr].image">
                <img :src=" data[indextr].image " class="w-10 h-10"/>
              </shipblu-td>

              <shipblu-td :data="data[indextr].sku">
                {{ data[indextr].sku }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].description">
                {{ data[indextr].description }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].incomingQuantity">
                {{ data[indextr].incoming_quantity }}
              </shipblu-td>

              <shipblu-td>
                <span @click="openStockProductModal('Edit', data[indextr])" 
                  :class="stockRequest.status === 'on_hold' ? 'text-primary' : 'disabled'">
                  <feather-icon
                    icon="EditIcon"
                    svgClasses="h-6 w-6"
                    class="mr-2"
                  />
                </span>
                <span @click="deleteProduct(data[indextr])" 
                  :class="stockRequest.status === 'on_hold' ? 'text-danger' : 'disabled'">
                  <feather-icon
                    icon="Trash2Icon"
                    svgClasses="h-6 w-6"
                  />
                </span>
              </shipblu-td>
            </shipblu-tr>
          </template>
        </shipblu-table>
        <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
      </div>
      <div class="lg:col-span-1 col-span-3">
        <shipblu-card class="lg:mt-5 p-5" :cardLoading="stockRequestLoading">
          <p class="text-blue-900 text-xl font-medium leading-tight mb-4">{{$t('URO Info')}}</p>
          <template v-if="!editable">
            <div>
              <span class="text-darkgray text-lg">{{$t('Merchant')}}:</span>
              <span class="text-primary text-lg font-medium"> {{stockRequest.merchant ? stockRequest.merchant.name : ''}}</span>
            </div>
            <div class="mt-1">
              <span class="text-darkgray text-lg">{{$t('Main Stock Request')}}:</span>
              <span class="text-primary text-lg font-medium cursor-pointer" @click="toStockRequestView()"> {{stockRequest.stock_request ? stockRequest.stock_request.id : ''}}</span>
            </div>
            <div class="mt-1">
              <span class="text-darkgray text-lg">{{$t('New stock Request')}}:</span>
              <span v-for="(stock, index) in newstockRequest" :key="index">
                <span class="text-primary text-lg font-medium"> {{stock.id}}</span> <span v-if="index !== newstockRequest.length - 1">,</span>
              </span>
            </div>
          </template>
          <template v-else>
            <div class="max-w-sm m-auto my-4">
              <v-select name="merchant" v-model="stockRequest.merchant" :placeholder="$t('Merchant')" label="name" :options="merchants"/>
            </div>
            <div class="max-w-sm m-auto my-4">
              <v-select name="stock request" v-model="stockRequest.stock_request" :placeholder="$t('Main Stock Request')" label="label" :options="stockRequests"/>
            </div>
          </template>
        </shipblu-card>
      </div>
    </div>
    <product-in-unidentified-stock v-if="$store.state.AppActiveUser.userRole === 'fc-receiving-agent' || $store.state.AppActiveUser.userRole ==='fc-inbounding-agent' || $store.state.AppActiveUser.userRole ==='fc-storing-agent'" :type="type" :stockProduct="stockProduct" :stockProductModal="stockProductModal"
    @loadStockProducts="loadStockProducts" @stockProductModal="stockProductModal = $event" :reasonsDic="reasonsDic"/>
  </div>
</template>
<script>
import {sendFulfillmentRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ProductInUnidentifiedStock from '../fcOperationsSupervisor/components/ProductInUnidentifiedStock.vue'
import vSelect from 'vue-select'
import i18nData from '../../i18n/i18nData.js'
import ShipbluCard from '../../layouts/components/ShipbluCard.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      editable: false,
      stockProductModal: false,
      newstockRequest: [],
      UROProducts: [],
      stockRequest: {
        fulfillment_center: {}
      },
      stockRequestLoading: false,
      stockProduct: {},
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      type: '',
      merchants: [],
      stockRequests: [],
      deleteData: {},
      tableLoader: false,
      reasonsDic: {'product_not_found': 'Product is not found in your catalog', 'product_not_matched': 'Product description is not matched', 'product_not_added_to_wro': 'Product was not added to the WRO'}
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ProductInUnidentifiedStock,
    vSelect,
    ShipbluCard,
    ShipbluPagination
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadStockProducts()
    }
  },
  methods: {
    openStockProductModal (type, data) {
      this.type = type
      this.stockProduct = data
      this.stockProductModal = true
    },
    cancelEdit () {
      this.editable = !this.editable
      this.loadStockRequest()
    },
    loadStockRequest () {
      this.stockRequestLoading = true
      sendFulfillmentRequest(true, false, this, `api/v1/fc/unidentified-stock-requests/${this.$route.params.stockID}/`, 'get', null, true,
        (response) => {
          this.stockRequest = response.data
          this.stockRequestLoading = false
        }
      )
    },
    loadStockProducts () {
      this.tableLoader = true
      this.searchInProgress = true
      const query = `?offset=${this.offset}&search=${this.searchVal}&limit=${this.maximumItems}`
      sendFulfillmentRequest(true, false, this, `api/v1/fc/unidentified-stock-requests/${this.$route.params.stockID}/products/${query}`, 'get', null, true,
        (response) => {
          this.UROProducts = response.data.results
          this.tableLoader = false
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadProductSearch)
    },
    loadProductSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadStockProducts()
    },
    updateURO () {
      const URO = {}
      URO.stock_request = this.stockRequest.stock_request ? this.stockRequest.stock_request.id : ''
      URO.merchant = this.stockRequest.merchant ? this.stockRequest.merchant.id : ''
      sendFulfillmentRequest(false, false, this, `api/v1/fc/unidentified-stock-requests/${this.$route.params.stockID}/`, 'patch', URO, true,
        () => {
          this.editable = false
          this.loadStockRequest()
        }
      )
    },
    deleteProduct (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendFulfillmentRequest(false, false, this, `api/v1/fc/unidentified-stock-requests/${this.$route.params.stockID}/products/${this.deleteData.id}/`, 'delete', null, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Product'] + i18nData[this.$i18n.locale][' has been deleted successfully!'],
            position: 'top-center'
          })
          this.loadStockProducts()
        }
      )
    },
    toStockRequestView () {
      this.$router.push({
        name : `${this.$store.state.AppActiveUser.userRole}-stock-request-view`,
        params: {
          stockID: this.stockRequest.stock_request.id
        }
      }).catch(() => {})
    },
    loadNewstockRequest () {
      sendFulfillmentRequest(false, false, this, `api/v1/fc/stock-requests/?uro=${this.$route.params.stockID}`, 'get', null, true,
        (response) => {
          this.newstockRequest = response.data.results
        }
      )
    }
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadStockRequest()
    this.loadStockProducts()
    this.loadNewstockRequest()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
.disabled {
  color: #C5CBD0;
  pointer-events: none;
}
.vx-timeline {
  margin-left: 0.5rem;
  padding-left: 40px;
  border-left: 2px dotted #e5e8eb;
  li {
    position: relative;

    .timeline-icon {
      position: absolute;
      top: 0;
      left: -3.2rem !important;
      border-radius: 50%;
      padding: 0px;
      padding: 0.3rem !important;
      padding-bottom: 0.4rem;
    }
  }
}
.product-order-status, .activity-e-status {
  margin-left: -30px;
}
.color-container {
  border: 1px solid #000;
  border-radius: 7px;
  padding: 2px;
  margin: auto !important;
  div {
    border-radius: 5px;
  }
}
</style>